.art-container-writer {
    width: 89%;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
}

.artes-writer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.7rem;
}

.galeria-artes-writer {
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.galeria-artes-hover-writer {
    width: 100%;
    max-width: 1200px;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.7rem;
}

.galeria-item-artes-writer {
    width: 100%;
    text-align: center;
}

.imagem-galeria-artes-writer {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    height: 42vh;
}

.nome-artista-artes-writer {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.nome-trabalho-artes-writer {
    font-size: 16px;
    margin-bottom: 5px;
}

.endereco-artes-writer {
    font-size: 14px;
    color: #555;
}

.custom-prev-arrow-art-page-writer,
.custom-next-arrow-art-page-writer {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 24px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    opacity: 0.4;
}

.custom-prev-arrow-art-page-writer {
    left: -20px;
}

.custom-next-arrow-art-page-writer {
    right: -20px;
}

.card-grid-writer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: transparent;
    padding: 10px;
}

.card-writer {
    cursor: pointer;
    background-color: #000000de;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;

    width: 155px;
    height: 235px;
    max-width: 300px;
    box-shadow: 0 1px 14px rgba(255, 0, 0, 0.404);
    margin-bottom: 15px;
    transition: transform .2s ease-in-out;
    border: 1px solid rgba(255, 0, 0, 0.641);

}

.card-writer:hover {
    transform: scale(1.2)
}

.art-photo-writer {
    width: 155px;
    height: 155px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: 1px solid white;
}

.pagination-writer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background-color: transparent;
}

.btn-next-writer {
    font-size: 17px;
    background-color: rgb(112, 112, 112);
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgb(14, 8, 8);
    padding: 4px;
}

.btn-next-writer:hover {
    font-size: 17px;
    background-color: rgb(208, 202, 202);
    color: #000000;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgb(14, 8, 8);
}

.pagina-numero-writer {
    font-size: 20px;
    margin: 10px;
    padding: 2px;
    box-shadow: 0 1px 10px rgb(14, 8, 8);
    border-radius: 4px;
}

.art-main-writer {
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    max-width: 1200px;
    min-height: 80vh;
}

.art-info-writer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.description-p-art-writer {
    font-size: 19px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    margin-top: 10px;
    background-color: #000000de;
    color: whitesmoke;
    border: 1px solid white;
}

.sobre-section-writer {
    max-width: 80%;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 55px rgba(104, 103, 103, 0.482);
    padding: 20px;
    margin: 25px;
    border: 1px solid rgb(166, 166, 166);
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 25px;
    flex-direction: column;
}

.input-container-adm-writer {
    position: relative;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: transparent;
}

.logo-red {
    width: 22px;
    background-color: transparent;
    position: absolute;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-left: 123px;
    margin-top: 39px;
    opacity: .8;
}

@media screen and (max-width: 576px) {
    .art-photo-writer {
        width: 150px;
        height: 150px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto
    }

    .card-writer {
        cursor: pointer;
        background-color: #000000de;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin-bottom: 0px;
        width: 150px;
        height: 222px;
        max-width: 300px;
        box-shadow: 0 1px 10px rgba(255, 0, 0, 0.253);
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border: 1px solid rgba(255, 0, 0, 0.641);

        flex-direction: column;
    }

    .description-p-art-writer {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        text-align: center;
        width: 150px;
    }

    .art-descript-writer {
        font-size: 15px;
    }

    .art-info-writer {
        font-size: 15px;
        width: 100%;

    }
}

@media screen and (max-width: 363px) {
    .art-photo-writer {
        width: 145px;
        height: 145px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto
    }

    .card-writer {
        cursor: pointer;
        background-color: #000000de;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(255, 0, 0, 0.504);
        padding: 10px;
        margin-bottom: 0px;
        width: 145px;
        height: 210px;
        max-width: 300px;
        border: 1px solid rgba(255, 0, 0, 0.641);
        margin-bottom: 5px;
    }

    .description-p-art-writer {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 145px;
    }

    .art-descript-writer {
        font-size: 15px;
    }

    .art-info-writer {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto
    }
}

@media screen and (max-width: 353px) {
    .art-photo-writer {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto
    }

    .card-writer {
        cursor: pointer;
        background-color: #000000de;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(255, 0, 0, 0.504);
        padding: 10px;
        margin-bottom: 0px;
        width: 120px;
        height: 190px;
        max-width: 300px;
        border: 1px solid rgba(255, 0, 0, 0.641);
        margin-bottom: 5px;
    }

    .description-p-art-writer {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 120px;
    }

    .art-descript-writer {
        font-size: 15px;
    }

    .art-info-writer {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto
    }

    .logo-red {
        width: 20px;
        background-color: transparent;
        position: absolute;
        display: flex;
        align-items: end;
        justify-content: end;
        margin-left: 97px;
        margin-top: 35px;
        opacity: .9;

    }
}