.galeria-item-artist-list {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.288);
  padding: 10px;
  /* Adiciona algum espaço interno entre as bordas e o conteúdo */
  box-sizing: border-box;
  /* Garante que o padding seja incluído nas dimensões totais */
  text-align: center;
  /* Centraliza o conteúdo */
  cursor: pointer;
}

.user-list-container-artist {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 1.5rem;
}

.user-list-header-artist {
  font-size: 1.7rem;
  margin-bottom: 25px;
  text-align: center;
  background-color: transparent;
}

.user-grid-artist {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

.galeria-item-list {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.user-item-artist {
  list-style: none;
  padding: 15px;
  border: 10px seagreen;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}


.user-avatar-artist {
  width: 113px;
  height: 113px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 1px solid #73737368;
  display: flex;
  margin: auto;
  box-shadow: 0 4px 10px #000000;
  margin-top: 5px;
  margin-bottom: 12px;
  transition: transform .8s ease-in-out;
}



.user-avatar-artist:hover {
  transform: rotateY(360deg);
}

.nome-user-artist {
  display: flex;
  padding-bottom: 20px;
  padding-top: 3px;
  font-size: 1.2rem;
  margin: auto;
  align-items: center;
  justify-content: center;
  color: black;
  height: 68px;
  text-align: center;
  width: 90%;
}

.container-artists-list {
  border-radius: 8px;
}

.clicavel-artist {
  border: 1px solid #909090;
  box-shadow: 0 0 5px #3333333e;
  transition: transform 0.3s;
}

.clicavel-artist:hover, .clicavel-artist:active {
  transform: scale(1.1);
}