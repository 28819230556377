/* ProfilePage.css */

.profile-container {
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  max-width: 1200px;
  min-height: 80vh;
}

.cover-photo {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 4px;
}

.profile-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: -75px;
  border: 5px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform .8s ease-in-out;
}



.profile-photo:hover {
  transform: rotateY(360deg);
}

.blur-effect {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.container-carrossel-perfil {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.user-info {
  text-align: center;
  margin-top: 20px;
}

.user-info p {
  font-size: 1.7rem;
  margin-bottom: 10px;
  width: 95%;
}

.loading-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #555;
}

.div-perfil {
  border: 3px solid rgba(0, 0, 0, 0.061);
}

.description-p {
  font-size: 1.5rem;
  width: 90%;
  display: flex;
  text-align: justify;
  line-height: 1.3;
  color: #333;
  position: relative;
  min-width: 150px;
  min-height: 150px;
  padding: 10px;
}

.logo-profile {
  position: absolute;
  top: 37%;
  left: 37%;
  opacity: 0.06;
  z-index: 0;
  /* Tente ajustar o z-index */
  border-radius: 50%;
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.description-data {
  display: flex;
}

.responsibility-p {
  margin-left: 0.5rem;
  padding: 1rem;
  font-size: 1.1rem;
}

.social-links {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-left: 35px;
}

.social-logo-profile {
  width: 25px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.social-logo:hover {
  transform: scale(1.2);
}

.social-links a:hover {
  text-decoration: none;
}