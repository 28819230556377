.art-container {
    width: 89%;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
}

.artes {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.7rem;
}

.galeria-artes {
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.galeria-artes-hover {
    width: 100%;
    max-width: 1200px;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.7rem;
}


.galeria-item-artes {
    width: 100%;
    text-align: center;
}

.imagem-galeria-artes {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    height: 42vh;
}


.nome-artista-artes {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.nome-trabalho-artes {
    font-size: 16px;
    margin-bottom: 5px;
}

.endereco-artes {
    font-size: 14px;
    color: #555;
}


.custom-prev-arrow-art-page,
.custom-next-arrow-art-page {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 24px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    opacity: 0.4;
}

.custom-prev-arrow-art-page {
    left: -20px;
}

.custom-next-arrow-art-page {
    right: -20px;
}

.card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: transparent;
}

.card {
    cursor: pointer;
    background-color: #000000;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgb(227, 8, 8);
    padding: 10px;

    width: 210px;
    height: 390px;
    max-width: 300px;
    box-shadow: 0 4px 14px rgb(14, 8, 8);
    margin-bottom: 15px;
    transition: transform .2s ease-in-out;
    border: 1px solid rgba(255, 0, 0, 0.641);
}

.stars span {
    background-color: #000000;
}

.card:hover {
    transform: scale(1.2);
}

.art-photo {
    width: 200px;
    height: 270px;
    border-radius: 4px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.51);
    border: 2px solid rgba(255, 255, 255, 0.85);
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 9px;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background-color: transparent;
    margin-bottom: 15px;
}

.btn-next {
    font-size: 17px;
    background-color: rgb(112, 112, 112);
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgb(14, 8, 8);
    padding: 4px;
    cursor: pointer;
}

.btn-next:hover {
    font-size: 17px;
    background-color: rgb(208, 202, 202);
    color: #000000;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgb(14, 8, 8);
}

.pagina-numero {
    font-size: 20px;
    margin: 10px;
    padding: 2px;
    box-shadow: 0 1px 10px rgb(14, 8, 8);
    border-radius: 4px;
}

.art-main {
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    max-width: 1200px;
    min-height: 80vh;
}

.art-info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #000000;
    margin-bottom: 15px;
}


.description-p-art {
    margin-top: 10px;
    font-size: 20px;
    height: 20px;
    text-align: center;
    color: #fff;
    background-color: #000000;
}

.logo-red-livros {
    width: 25px;
    background-color: transparent;
    position: absolute;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-left: 180px;
    margin-top: 0px;


    opacity: .8;
}


@media screen and (max-width: 576px) {
    .art-photo {
        width: 185px;
        height: 240px;
        border-radius: 4px;
        background-color: #000000;
    }


    .card {
        cursor: pointer;
        background-color: #000000;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(227, 8, 8);
        padding: 10px;
        margin-bottom: 0px;
        width: 185px;
        height: 365px;
        max-width: 350px;
        box-shadow: 0 1px 10px rgb(14, 8, 8);
        margin-bottom: 5px;
        border: 1px solid rgba(255, 0, 0, 0.641);
    }

    .description-p-art {
        font-size: 17px;
        text-align: center;
        height: 30px;
    }

    .art-descript {
        font-size: 15px;
    }

    .art-info {
        font-size: 15px;
        text-align: center;
    }

    .logo-red-livros {
        width: 22px;
        background-color: transparent;
        position: absolute;
        display: flex;
        align-items: end;
        justify-content: end;
        margin-left: 160px;
        margin-top: 0px;
        opacity: .7;

    }
}

@media screen and (max-width: 413px) {
    .art-photo {
        width: 163px;
        height: 240px;
        border-radius: 4px;
        background-color: #000000;
    }


    .card {
        cursor: pointer;
        background-color: #000000;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(227, 8, 8);
        padding: 10px;
        margin-bottom: 0px;
        width: 163px;
        height: 365px;
        max-width: 350px;
        box-shadow: 0 1px 10px rgb(14, 8, 8);
        margin-bottom: 5px;
        border: 1px solid rgba(255, 0, 0, 0.641);
    }

    .description-p-art {
        font-size: 17px;
        text-align: center;
        height: 30px;
    }

    .art-descript {
        font-size: 15px;
    }

    .art-info {
        font-size: 15px;
        text-align: center;
    }

    .logo-red-livros {
        width: 22px;
        background-color: transparent;
        position: absolute;
        display: flex;
        align-items: end;
        justify-content: end;
        margin-left: 150px;
        margin-top: 0px;
        opacity: .7;

    }

}

@media screen and (max-width: 370px) {
    .art-photo {
        width: 155px;
        height: 220px;
        border-radius: 4px;
        background-color: #000000;
    }


    .card {
        cursor: pointer;
        background-color: #000000;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(227, 8, 8);
        padding: 10px;
        margin-bottom: 0px;
        width: 155px;
        height: 355px;
        max-width: 350px;
        box-shadow: 0 1px 10px rgb(14, 8, 8);
        margin-bottom: 5px;
        border: 1px solid rgba(255, 0, 0, 0.641);
    }

    .description-p-art {
        font-size: 17px;
        text-align: center;
        height: 30px;
    }

    .art-descript {
        font-size: 15px;
    }

    .art-info {
        font-size: 15px;
        text-align: center;
    }
}

@media screen and (max-width: 353px) {
    .art-photo {
        width: 137px;
        height: 180px;
        border-radius: 4px;
        background-color: #000000;
    }


    .card {
        cursor: pointer;
        background-color: #000000;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(227, 8, 8);
        padding: 10px;
        margin-bottom: 0px;
        width: 137px;
        height: 325px;
        max-width: 350px;
        box-shadow: 0 1px 10px rgb(14, 8, 8);
        margin-bottom: 5px;
        border: 1px solid rgba(255, 0, 0, 0.641);
    }

    .description-p-art {
        font-size: 17px;
        text-align: center;
        height: 30px;
    }

    .art-descript {
        font-size: 15px;
    }

    .art-info {
        font-size: 15px;
        text-align: center;
    }

    .logo-red-livros {
        width: 20px;
        background-color: transparent;
        position: absolute;
        display: flex;
        align-items: end;
        justify-content: end;
        margin-left: 132px;
        margin-top: 0px;
        opacity: .7;

    }
}

@media screen and (max-width: 317px) {
    .art-photo {
        width: 125px;
        height: 180px;
        border-radius: 4px;
        background-color: #000000;
    }


    .card {
        cursor: pointer;
        background-color: #000000;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(227, 8, 8);
        padding: 10px;
        margin-bottom: 0px;
        width: 125px;
        height: 325px;
        max-width: 350px;
        box-shadow: 0 1px 10px rgb(14, 8, 8);
        margin-bottom: 5px;
        border: 1px solid rgba(255, 0, 0, 0.641);
    }

    .description-p-art {
        font-size: 17px;
        text-align: center;
        height: 30px;
    }

    .art-descript {
        font-size: 15px;
    }

    .art-info {
        font-size: 15px;
        text-align: center;
    }

    .logo-red-livros {
        width: 17px;
        background-color: transparent;
        position: absolute;
        display: flex;
        align-items: end;
        justify-content: end;
        margin-left: 132px;
        margin-top: 0px;

    }
}