.art-container-page {
  width: 95%;
  max-width: 1200px;
  margin: 10px auto;
  margin-bottom: 2rem;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.art-photo-livro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  /* width: 90%;
  height: 90vw;
  max-height: 100vh; */

  background-size: contain;
  border-radius: 8px;
  max-height: 300px;

}

.description-p-art {
  text-align: justify;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  padding-bottom: 2vh;
}

.nome-escritor {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;

}

.name-p-book {
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  margin-top: 20px;
}

.art-descript {
  margin-top: 20px;
  text-align: center;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  justify-content: center;
}

.x-back-home {
  width: 1.5rem;
  background-color: transparent;
  color: #000000;
  padding: 7px;
  font-size: 1.7rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-decoration: none;
}


.livro {
  font-size: 1.3rem;
  background-color: #f5f3e5;
  color: #040303;
  text-align: justify;
  line-height: 1.4;
  text-indent: 20px;
  padding: 10px;
  border: 1px solid rgb(208, 205, 205);
  border-radius: 4px;
  box-shadow: 0 5px 25px rgb(124, 121, 121);
}

.livro-branca {
  font-size: 1.3rem;
  background-color: #fcfcfc81;
  color: #040303;
  text-align: justify;
  line-height: 1.4;
  text-indent: 20px;
  padding: 10px;
  border: 1px solid rgb(208, 205, 205);
  border-radius: 4px;
  box-shadow: 0 5px 25px rgb(124, 121, 121);
}

.livro:hover {
  border-bottom-right-radius: 65px;
}

.page-container {
  overflow: hidden;
}

.page {
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin-bottom: 20px;

  backface-visibility: hidden;
  transition: transform 1.2s ease-in-out;
}


.page.flip {
  transform: rotateY(360deg);
}

.page-content {
  margin-bottom: 20px;
}

.page-number {
  width: 25%;
  padding: 10px;
  bottom: 5px;
  left: 10px;

  font-size: 19px;
  justify-self: end;
  align-items: end;
}


.p-livro {
  color: #333333;
  background-color: #f5f3e5;
  line-height: 1.4;
  text-indent: 15px;
  text-align: justify;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
}

.p-livro-branca {
  color: #333333;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 55px;
  background-color: #fcfcfc81;
  line-height: 1.4;
  text-indent: 15px;
  text-align: justify;
  padding-top: 5px;
}



.pagination-buttons {
  display: flex;

  margin: auto;
  justify-content: center;
}

.btn-page {
  width: 80px;
  font-size: 19px;
  padding: 7px;
  margin: 2px;
  box-shadow: 0 2px 5px rgba(2, 2, 2, 0.715);
  border-radius: 8px;
}

.btn-page:hover {
  width: 80px;
  font-size: 20px;
  padding: 7px;
  background-color: #000000;
  color: #ddd;
  box-shadow: 0 2px 5px rgba(2, 2, 2, 0.715);
  border-radius: 8px;
}

.btn-page-download {
  font-size: 19px;
  padding: 7px;
  margin: 2px;
  box-shadow: 0 2px 5px rgba(2, 2, 2, 0.715);
  border-radius: 8px;
  margin-left: 5px;
  cursor: pointer;
}

.btn-page-download:hover {
  background-color: #e7e7e7bd;
  font-size: 21px;
  padding: 7px;
  margin: 2px;
  box-shadow: 0 2px 7px rgba(2, 2, 2, 0.869);
  margin-left: 5px;
}

.btns-go-download {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.input-go-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 10px;
}

.input-go {
  width: 25%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 5px;
}

.go-button {
  background-color: #ffffff;
  color: #363636;
  padding: 8px 12px;
  /* border: 1px solid black; */
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 5px;
  box-shadow: 0 2px 10px rgba(2, 2, 2, 0.715);
  margin-left: 5px;
}

.go-button:hover {
  background-color: #b6b6b62a;
  color: #000;
  border: 1px solid black;
}

.white {
  background-color: #fcfcfc81;
}

.selectField-book {
  font-size: 18px;
  background-color: #ffffff;
  color: #403f3f;

  border: 1px solid #ccc;

  border-radius: 5px;
  cursor: pointer;
  padding: 2px
}

.selectField-book option:hover,
.selectField-book option:focus {
  background-color: #f5f3e5;
  /* Cor de fundo desejada */
}

.yellow {
  background-color: #f5f3e5;
  color: #070707;
  border: 2px solid #f5f3e5;
}

.yellow:hover {
  background-color: #e6c809;
  color: #070707;
  border: 2px solid #f5f3e5;
}

.options-color-page {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 7px;
  padding: 3px;
}

.container-page-number-color-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  padding: 3px;
}

.p-color-page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-right: 5px;
  font-size: 18px;
}

.container-book-pages {
  display: flex;
  max-width: 1200px;
  align-items: center;
  margin: auto;
}

.div-inside {
  width: 100%;
  background-color: #ffffff;
  height: 45px;
  position: absolute;
}

.div-inside-back {
  width: 100%;
  background-color: #000000;
  height: 100px;
  position: absolute;
  margin-bottom: 500px;
}

.h1-em-breve {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
}

.content {
  min-height: 92vh;
}