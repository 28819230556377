.footer {
  color: #333;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
  /* ou position: relative; */
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.114);
}

.footer-data {
  display: flex;
  font-size: 15px;
}

.footer-data-1 {
  padding: 15px;
}

.footer-data-1 h5 {
  font-size: 20px;
}


.social-logo-profile-email {
  width: 15px;
  padding: 3px;
  cursor: pointer;
}

.social-logo-profile-you {
  width: 17px;
  padding: 3px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
    /* Centraliza o texto quando a largura da tela é menor que 600px */
  }
}