.art-admin-container {
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 1200px;
}


.container-home-admin {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 5px;
  justify-content: center;
  max-width: 1200px;
}

.cards-arte {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  padding: 3px;
}

.arte-item-admin {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  width: calc(50% - 16px);
  box-sizing: border-box;
}

.art-photo-admin {
  width: 100%;
  max-width: 150px;
  min-width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.inputField-adm {
  max-width: 1200px;
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  border-radius: 35px;
  position: relative;

}

.inputField-adm-artist {
  max-width: 1200px;
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  border-radius: 35px;
  position: relative;
  margin-top: 20px;
}

.search-icon-adm {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  width: 1.4rem;
  position: absolute;
  top: 50%;
  right: 200px;
  transform: translateY(-50%);
  z-index: 1;
}


.input-container-adm {
  position: relative;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: transparent;
}

.selectField-adm {
  padding: 3px;
  font-size: 16px;
  margin-left: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 2px rgba(14, 8, 8, 0.396);

}


.search-icon-adm-artist {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  width: 1.4rem;
  position: absolute;
  top: 50%;
  right: 30vw;
  transform: translateY(-50%);
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .search-icon-adm-artist {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    width: 1.4rem;
    position: absolute;
    top: 50%;
    right: 95px;
    transform: translateY(-50%);
    z-index: 1;
  }

  .search-icon-adm {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    width: 1.4rem;
    position: absolute;
    top: 50%;
    right: calc(15.9vw + 27px);
    transform: translateY(-50%);
    z-index: 1;
  }

  .selectField-adm {
    padding: 3px;
    font-size: 12.5px;
    margin-left: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .search-icon-adm-artist {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    width: 1.4rem;
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
    z-index: 1;
  }

  .search-icon-adm {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    width: 1.4rem;
    position: absolute;
    top: 50%;
    right: 140px;
    transform: translateY(-50%);
    z-index: 1;
  }

  .selectField-adm {
    padding: 3px;
    font-size: 12.5px;
    margin-left: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
}

@media screen and (max-width: 400px) {
  .search-icon-adm-artist {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    width: 1.4rem;
    position: absolute;
    top: 50%;
    right: 42px;
    transform: translateY(-50%);
    z-index: 1;
  }

  .selectField-adm {
    padding: 3px;
    font-size: 12.5px;
    margin-left: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
}