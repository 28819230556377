/* Adicionando o sufixo "-admin" a todas as classes */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.header-admin {
  background-color: #f8f8f8;
  color: #333;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-admin nav.open ul {
  display: block;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  background-color: #f8f8f8;
  z-index: 1000;
}

.header-admin h1 {
  margin: 0;
}

.header-admin nav {
  margin-top: 10px;
}

.header-admin nav ul {
  width: 100%;
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 1rem;
}

.header-admin nav ul li {
  margin: 0 15px;
  display: inline-block;
}

.header-admin nav ul li a {
  text-decoration: none;
  color: #333;
}

.header-admin nav ul li a:hover {
  text-decoration: underline;
}

.menu-button-admin {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
}

.head-1-admin {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.titulo-esquerda-admin {
  color: #333;
  font-size: 2.1rem;
  display: flex;
  align-items: center;
}

.lista-direita-admin {
  font-size: 1.5rem;
}

.link-sem-sublinhado-admin {
  text-decoration: none;
  color: #333;
}

.li-header {
  background-color: white;
}

.logo-admin {
  width: 100%;
  height: auto;

  border-bottom-right-radius: 42%;
  padding: 1px;
  margin-right: 15px;
  max-width: 60px;
  /* border: 1px solid greenyellow; */
}

/* Restante do CSS para diferentes tamanhos de tela */

@media screen and (max-width: 750px) {
  .header-admin {
    padding: 0;
    margin-top: 5px;
  }

  .header-admin nav ul {
    display: none;
  }

  .header-admin nav ul li {
    margin: 5px 0;
    display: block;
  }

  .header-admin nav.open ul {
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1-admin {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button-admin {
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 2rem;
  }

  .lista-direita-admin {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda-admin {
    width: 100%;
    color: #333;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-right: 0px;
    text-decoration: none;
  }

  .logo-admin {
    width: 100%;
    height: auto;

    border-bottom-right-radius: 42%;
    padding: 1px;
    max-width: 45px;
    margin-right: 7px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 600px) {
  .header-admin {
    padding: 0;
    margin-top: 5px;
  }

  .header-admin nav ul {
    display: none;
  }

  .header-admin nav ul li {
    margin: 5px 0;
    display: block;
  }

  .header-admin nav.open ul {
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1-admin {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button-admin {
    top: 5px;
    right: 10px;
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 1.9rem;
  }

  .lista-direita-admin {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda-admin {
    width: 100%;
    color: #333;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-right: 50px;
    text-decoration: none;
  }

  .logo-admin {
    width: 100%;
    height: auto;

    border-bottom-right-radius: 42%;
    padding: 1px;
    max-width: 50px;
    margin-right: 4px;
    margin-left: 3px;
  }
}

@media screen and (max-width: 350px) {
  .header-admin {
    padding: 0;
    margin-top: 5px;
  }

  .header-admin nav ul {
    display: none;
  }

  .header-admin nav ul li {
    margin: 5px 0;
    display: block;
  }

  .header-admin nav.open ul {
    display: block;
    position: absolute;
    top: 45px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1-admin {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button-admin {
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 2rem;
  }

  .lista-direita-admin {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda-admin {
    width: 100%;
    color: #333;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    margin-right: 20px;
    text-decoration: none;
  }

  .logo-admin {
    width: 100%;
    height: auto;

    border-bottom-right-radius: 42%;
    padding: 1px;
    max-width: 45px;
    margin-right: 4px;
    margin-left: 3px;
  }
}

@media screen and (max-width: 280px) {
  .header-admin {
    padding: 0;
    margin-top: 5px;
  }

  .header-admin nav ul {
    display: none;
  }

  .header-admin nav ul li {
    margin: 5px 0;
    display: block;
  }

  .header-admin nav.open ul {
    display: block;
    position: absolute;
    top: 45px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1-admin {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button-admin {
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 2rem;
  }

  .lista-direita-admin {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda-admin {
    width: 100%;
    color: #333;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    margin-right: 37px;
    text-decoration: none;
  }

  .logo-admin {
    width: 100%;
    height: auto;

    border-bottom-right-radius: 42%;
    padding: 1px;
    max-width: 40px;
    margin-right: 7px;
    margin-left: 4px;
  }
}