* {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #403f3f;
}


.main-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    justify-content: center;
    margin: auto;
}

.container-home {
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    flex: 1;
}

.input-container {
    position: relative;
    width: 100%;
    margin-top: 10px;
}

.inputField {
    width: 80%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 0.9rem;
    border-radius: 35px;
    padding-right: 0px;
    /* Adiciona espaço para a imagem */
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: black;
    width: 1.4rem;
}

.titulo-sobre {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    background-color: #ffffff;
}

.sobre-section {
    max-width: 80%;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 55px rgba(104, 103, 103, 0.482);
    padding: 20px;
    margin: 25px;
    border: 1px solid rgb(0, 0, 0);
}

.sobre-section-ia {
    max-width: 80%;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 55px rgba(104, 103, 103, 0.482);
    padding: 20px;
    margin: 25px;
    border: 1px solid rgb(166, 166, 166);
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    max-width: 1100px;
}


.container-escritores {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background: none;
}

.sobre-section-escritores {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    /* margin: 50px auto; */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 55px rgba(104, 103, 103, 0.482);
    padding: 20px;
    margin: 25px;
    border: 1px solid rgb(166, 166, 166);
}

.about-sec {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.intro-text {
    color: #333;
    line-height: 1.6;
    font-size: 1.1rem;
    margin-bottom: .5rem;
    background-color: #ffffff;
}

.text-sobre {
    margin-bottom: 1rem;
    background-color: #ffffffe8;
}

.text-sobre-escritores {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 1rem;
    background-color: #ffffffe8;
}

.sobre-btn {
    background-color: #2e2929;
    color: #ffffff;
    padding: 10px 20px;
    border: .5px solid rgb(255, 0, 0);
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.536);
}

.sobre-btn:hover {
    background-color: #030303;
    color: #ffffff;
    padding: 12px 20px;
    border: .5px solid rgb(255, 0, 0);
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.536);
}

.sobre-btn-faca {
    display: flex;
    align-items: end;
    justify-content: end;
    margin: auto;
    background-color: #2e2929;
    color: #ffffff;
    padding: 7px 20px;
    border: .5px solid rgb(255, 0, 0);
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 10px 14px rgba(0, 0, 0, 0.536);
}

.sobre-btn-faca:hover {
    display: flex;
    align-items: end;
    justify-content: end;
    margin: auto;
    background-color: #050505;
    color: #ffffff;
    padding: 10px 22px;
    border: .5px solid rgb(255, 0, 0);
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.536);
}

@media screen and (max-width: 768px) {
    /* .about-sec {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sobre-section {
        padding: 15px;
        margin: 10px;
    } */
}

@media screen and (max-width: 600px) {
    .inputField {
        margin-top: 5px;
    }

    .about-sec {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sobre-section {
        padding: 15px;
        margin: 10px;
    }

    .sobre-section-ia {
        padding: 15px;
        margin: 10px;
    }
}

.map-container {
    display: flex;
    width: 50%;
    align-items: center;
}