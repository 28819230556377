.user-list-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.user-list-header {
  font-size: 1.7rem;
  margin-bottom: 25px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.user-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.user-item {
  list-style: none;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}


.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid rgb(117, 117, 116);
}

.nome-user {
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.clicavel {
  border: 1px solid #909090;
  box-shadow: 0 0 5px #3333333e;
}



.clicavel {
  transition: transform 0.3s;
}

.clicavel:hover, .clicavel:active {
  transform: scale(1.1);
}

.on {
  width: 10px;
  height: 10px;
  background-color: rgb(153, 255, 0);
  border: 1px solid rgba(0, 0, 0, 0.441);
  border-radius: 50%;
  margin-left: 5px;
}

.off {
  width: 10px;
  height: 10px;
  background-color: red;
  border: 1px solid black;
  border-radius: 50%;
  margin-left: 2px;
}