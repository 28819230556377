/* Loading.css */

.loading-container {
  display: flex;
  min-height: 100vh;
  margin: 0;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* Cor de fundo da página */
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-logo {
  width: 80px;
  height: 80px;

  /* margin-right: 1.2ex; */

  /* padding: 3px; */


}

@keyframes scaleUp {
  from {
    transform: scale(1);
    /* Começa com o tamanho normal */
  }

  to {
    transform: scale(1.5);
    /* Termina com 1.5x o tamanho original */
  }

  to {
    transform: scale(13);
    /* Termina com 1.5x o tamanho original */
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}