/* Adicionando o sufixo "-admin" a todas as classes */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* @font-face {
  font-family: 'Calopsita';
  src: local('Salium'), url(./fonts/Calopsita/Calopsita-Regular.ttf) format('truetype');
} */

.header {
  /* background-color: #f8f8f8; */
  color: #333;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header nav.open ul {
  display: block;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  background-color: #f8f8f8;
  z-index: 1000;
}

.header h1 {
  margin: 0;
}

.header nav {
  margin-top: 10px;
}

.header nav ul {
  width: 100%;
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 1rem;
}

.header nav ul li {
  margin: 0 15px;
  display: inline-block;
}

.header nav ul li a {
  text-decoration: none;
  color: #333;
}

.header nav ul li a:hover {
  text-decoration: underline;
}

.menu-button {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
}

.head-1 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.titulo-esquerda {
  color: #a61e1e;
  font-size: 2.1rem;
  display: flex;
  align-items: center;
}

.lista-direita {
  font-size: 1.5rem;
}

.link-sem-sublinhado {
  text-decoration: none;
  color: #333;
  margin-left: 3px;
}

.link-sem-sublinhado-red {
  text-decoration: none;
  color: #5d0000fa;
  margin-left: 3px;
}

.logo {
  width: 100%;
  height: auto;
  background-color: #c3c3c384;
  border-radius: 5%;
  border-bottom-right-radius: 45%;
  /* border-bottom-left-radius: 45%; */
  /* padding: 1px; */
  margin-right: 15px;
  max-width: 60px;
  /* box-shadow: 0 4px 14px rgb(14, 8, 8); */
  /* border: 0.2px solid rgb(153, 255, 0); */
  transition: transform .8s ease-in-out;


}

.logo:hover {
  transform: rotateY(360deg);
}

.header-login-icon {
  width: 23px;
  background-color: rgb(63, 253, 0);
  border-radius: 50%;
}


/* Restante do CSS para diferentes tamanhos de tela */

@media screen and (max-width: 750px) {
  .header {
    padding: 0;
    margin-top: 5px;
  }

  .header nav ul {
    display: none;
  }

  .header nav ul li {
    margin: 0;
    display: block;
  }

  .header nav.open ul {
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1 {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button {
    top: 0px;
    right: 10px;
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 2rem;
  }

  .lista-direita {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda {
    width: 100%;
    color: #333;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-right: 0px;
    text-decoration: none;
  }

  .logo {
    width: 100%;
    height: auto;
    background-color: #c3c3c384;
    border-radius: 5%;
    /* padding: 1px; */
    max-width: 45px;
    margin-right: 7px;
    margin-left: 50px;
    transition: transform .8s ease-in-out;

  }

  .logo:hover {
    transform: rotateY(360deg);
  }


}

@media screen and (max-width: 600px) {
  .header {
    padding: 0;
    margin-top: 5px;
  }

  .header nav ul {
    display: none;
  }

  .header nav ul li {
    margin: 0;
    display: block;
  }

  .header nav.open ul {
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1 {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button {
    top: 0px;
    right: 10px;
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 1.9rem;
  }

  .lista-direita {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda {
    width: 100%;
    color: #333;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-right: 50px;
    text-decoration: none;
  }

  .logo {
    width: 100%;
    height: auto;
    background-color: #c3c3c384;
    border-radius: 10%;
    /* padding: 1px; */
    max-width: 50px;
    margin-right: 4px;
    margin-left: 3px;
    transition: transform .8s ease-in-out;

  }

  .logo:hover {
    transform: rotateY(360deg);
  }

}


@media screen and (max-width: 350px) {
  .header {
    padding: 0;
    margin-top: 5px;
  }

  .header nav ul {
    display: none;
  }

  .header nav ul li {
    margin: 0;
    display: block;
  }

  .header nav.open ul {
    display: block;
    position: absolute;
    top: 45px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1 {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button {
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 2rem;
  }

  .lista-direita {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda {
    width: 100%;
    color: #333;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    margin-right: 20px;
    text-decoration: none;
  }

  .logo {
    width: 100%;
    height: auto;
    background-color: #c3c3c384;
    border-radius: 10%;
    /* padding: 1px; */
    max-width: 40px;
    margin-right: 4px;
    margin-left: 3px;
    transition: transform .8s ease-in-out;

  }

  .logo:hover {
    transform: rotateY(360deg);
  }

}

@media screen and (max-width: 280px) {
  .header {
    padding: 0;
    margin-top: 5px;
  }

  .header nav ul {
    display: none;
  }

  .header nav ul li {
    margin: 5px 0;
    display: block;
  }

  .header nav.open ul {
    display: block;
    position: absolute;
    top: 45px;
    left: 0;
    width: 90%;
    background-color: #f8f8f8;
    z-index: 1000;
    border-bottom: 2px solid #3333331f;
    border-radius: 16px;
  }

  .head-1 {
    flex-direction: column;
    padding: 3px;
    padding-left: 2px;
    font-size: 15px;
  }

  .menu-button {
    display: block;
    cursor: pointer;
    color: #333;
    font-size: 1.8rem;
  }

  .lista-direita {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .titulo-esquerda {
    width: 100%;
    color: #333;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-right: 55px;
    text-decoration: none;
  }

  .logo {
    width: 100%;
    height: auto;
    background-color: #c3c3c384;
    border-radius: 10%;
    /* padding: 1px; */
    max-width: 40px;
    margin-right: 4px;
    margin-left: 3px;
    transition: transform .8s ease-in-out;

  }

  .logo:hover {
    transform: rotateY(360deg);
  }



}


.header-list-hover:hover {
  color: #347b2b;
  cursor: pointer;
}