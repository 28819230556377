.about-section {
  width: 100%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 1200px;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
}


.about-h2 {
  width: 100%;
  color: #000000;
  font-size: 1.7rem;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-p {
  width: 85%;
  margin: auto;
  color: #555;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 15px;
  /* text-indent: 20px; */
  word-wrap: break-word;
}

.container-logo {
  padding: 1rem 0 1rem;
  max-width: 1200px;
  justify-content: center;
  margin: auto;
}

.logo-about {
  width: 80%;
  /* background-color: #00000098; */
  border-radius: 10%;
  display: flex;
  margin: auto;
  padding: 3px;
  /* border-bottom-right-radius: 37%; */
  opacity: 0.8;
  max-width: 600px;
  /* box-shadow: 0 -2px 35px rgb(14, 8, 8); */
  margin-bottom: 35px;
  transition: transform .8s ease-in-out;
  border-bottom-right-radius: 42%;
  border-bottom-left-radius: 40%;

}

.logo-about:hover {
  transform: rotateY(360deg);
}




@media screen and (max-width: 600px) {

  /* Estilos para telas menores */
  /* .about-section {
      padding: 15px;
    } */
  .logo-about {
    width: 80%;
    /* background-color: #00000098; */
    border-radius: 10%;
    display: flex;
    margin: auto;
    padding: 3px;
    max-width: 600px;
    /* box-shadow: 0 -2px 35px rgb(14, 8, 8); */
    margin-bottom: 35px;
    transition: transform .8s ease-in-out;
    border-bottom-right-radius: 45%;

  }

  .logo-about:hover {
    transform: rotateY(360deg);
  }

}