.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 15px;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    align-items: center;
}

.cookie-popup p {
    bottom: 0;
    left: 0;
    width: 90%;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    text-align: center;
    /* padding: 15px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cookie-popup a {
    color: #ffe677;
    text-decoration: underline;
    background-color: #00000000;
}

.accept-btn {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #FFD700;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.close-btn {
    padding: 5px 15px;
    margin-left: 25px;
    background-color: transparent;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border: none;
    cursor: pointer;
    background-color: #ff00008c;
    border: 1px solid rgba(255, 255, 0, 0.406);
}

.btns-div {
    display: flex;
    background-color: rgba(15, 15, 15, 0.043);
}