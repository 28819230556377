.title-profile-artist {
    width: 90%;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.6rem;
    /* margin-bottom: 1rem; */
    margin: auto;
}