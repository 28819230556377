/* TermsOfUse.css */
.cantainer-privacy-main {
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    /* word-break: break-all; */
    line-height: 1.6;
    margin-left: 15px;
}

.container-privacy {
    width: 95%;
    font-family: Arial, sans-serif;
    color: #333;
    /* padding: 10px; */
    max-width: 1200px;
    word-wrap: break-word;
    margin-bottom: 20px;
}

.container-privacy p {
    font-size: 22px;
    word-wrap: break-word;
}

.heading-privacy {
    color: #2c3e50;
    font-weight: bold;
    display: flex;
    align-items: center;

}

.link-privacy {
    color: #3498db;
    text-decoration: none;
}

.link-privacy:hover {
    text-decoration: underline;
}