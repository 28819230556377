.map-container {
  display: flex;
  width: 100%;
}

.p-login {
  width: 80px;
  background-color: transparent;
  color: rgb(0, 0, 0);
  border-radius: 4px;
  margin-bottom: 10px;
}

.span-salvar-email {
  width: 80px;
  background-color: transparent;
  color: rgb(0, 0, 0);
  border-radius: 4px;
}

.login-form {
  /* background-image: url('../assets/logo-horror.png'); */
  background-size: cover;
  width: 93%;
  margin: auto;
  padding: 1vw;
  border: 1px solid #020202;
  border-radius: 10px;
  box-shadow: 0 29px 100px rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  z-index: 2;
  transition: all 0.3s ease;
  max-width: 1200px;
  max-height: 90vh;
  transition: filter 0.3s;

}

.loading-login {
  width: 70px;
  padding: 3px;
  border-bottom-right-radius: 40%;
  border-bottom-right-radius: 40%;

  transition: transform .8s ease-in-out;
}

.loading-login:hover {
  transform: rotateY(360deg);

}

/* .login-form:hover {
  width: 80%;
  padding: 2vw;
  background-color: #ffffff;
  color: #000;
  max-width: 1200px;
  max-height: 80vh;
  filter: brightness(150%);

} */

.login-width {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 90vh;
  flex-direction: column;
  transition: filter 0.3s;
  position: relative;
}


.login-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 1.8rem;
  color: #333;
  background-color: rgba(40, 44, 52, 0);
  margin-bottom: 15px;
}

.login-label {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 1.8rem;
  color: #ffffff;

  background-color: rgba(224, 227, 232, 0);
}

.btn-senha {
  position: absolute;
  width: 1.7rem;
  background-color: #ffffff00;
  border: none;
  right: 15%;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 10px;
}



.login-input {
  width: 85%;
  z-index: 1;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid #000000;
  font-size: 30px;
  color: #010101fa;
  background-color: #ffffff;
  box-shadow: 0 12px 15px rgb(255, 255, 255);
  margin-left: 15px;
  height: auto;
  text-indent: 10px
}



.login-button {
  background-color: #ebeaea;
  box-shadow: 0 7px 25px rgb(0, 0, 0);
  width: 75%;
  height: 80px;
  margin-left: 4px;
  color: #060606;
  padding: 15px 25px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 25px;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 1px solid rgb(6, 6, 6);
  z-index: 1;
}

.login-button:hover {
  background-color: #353535;

  color: rgb(255, 255, 255);

  border: 1px solid rgb(123, 123, 123);
}



.login-error-message {
  color: #ffffff;
  font-size: 2vw;
  margin-top: 10px;
  width: 100%;
  background-color: #ed1010;
  border-radius: 4px;
  padding: 5px;
}

.login-error-message-cap {
  color: #ffffff;
  font-size: 20px;
  margin-top: 10px;
  width: 95%;
  background-color: #ed1010;
  border-radius: 4px;
  padding: 5px;

}


.login-label-checkbox {
  width: 10rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 2rem;
  color: #ffffff;
  background-color: transparent
}

.login-label-checkbox-input {
  width: 10rem;
  display: flex;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #000000;
  background-color: transparent;
  margin-top: 15px;
}


.checkbox-container {
  width: 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: rgba(170, 176, 189, 0);
}

.checkbox-input {
  width: 1rem;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-custom {
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-left: 3px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  margin-top: 15px;
}

.checkbox-checkmark {
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}


.checkbox-input:checked+.checkbox-custom {
  background-color: #000000;
  border: 2px solid #61dafb;
}

.checkbox-input:checked+.checkbox-custom .checkbox-checkmark {
  opacity: 1;
}

/* Estilos para a caixa do reCAPTCHA */
/* Estilos para a caixa do reCAPTCHA */
.captcha {
  width: 90%;
  height: 110px;

  margin-left: 10px;
  margin-bottom: 45px;
  display: flex;

}


.logo-login {
  width: 100%;
  max-width: 100px;
  height: auto;
  border-radius: 50%;
  border: 1px solid greenyellow;
  margin: auto;
  z-index: -2;
  box-shadow: 0 12px 15px rgb(0, 0, 0);
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: -65px;
  opacity: .1;

}

.title-login {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  font-size: 45px;

  margin-bottom: 7px;
}

.p-btn-login {
  width: 25%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  color: #ffffff;
  font-size: 27px;
}


@media screen and (max-width: 364px) {
  .captcha {
    width: 70%;
    height: 160px;

    margin-left: 10px;
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;

  }

  .login-button {
    width: 100%;
    height: 50px;
    margin-left: 0px;
    font-size: 22px;
    display: flex;
  }

  .login-input {
    width: 95%;
    z-index: 1;
    padding: 0;
    margin: 0;
    border: none;
    border-bottom: 1px solid #000000;
    font-size: 20px;
    color: #010101fa;
    background-color: #ffffff;
    box-shadow: 0 12px 15px rgb(255, 255, 255);
    margin-left: 5px;
    height: auto;
    text-indent: 10px
  }

}

/* 
@media screen and (max-width: 500px) {

  .logo-login {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 50%;
    border: 1px solid greenyellow;
    display: block;
    margin: auto;
    margin-top: -252px;
    position: absolute;
    z-index: 1;
    margin-left: 15%;
    opacity: .2;
  }
}

@media screen and (max-width: 474px) {

  .logo-login {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 50%;
    border: 1px solid greenyellow;
    display: block;
    margin: auto;
    margin-top: -250px;
    position: absolute;
    z-index: 1;
    margin-left: -0;
    opacity: .2;
  }
}  */