* {
  margin: 0;
  padding: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.galeria {
  background-color: #dddbdb;
  width: 80%;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.galeria-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.imagem-galeria {
  width: calc(100% - 13px);
  height: 250px;
  max-width: 200px;
  border-radius: 4px;
  margin: 0 auto;
  /* object-fit: cover; */
  box-shadow: 0 1px 25px rgba(255, 1, 1, 0.749);
  margin-bottom: 15px;
  border: 1px solid rgb(64, 41, 8);

}

.imagem-galeria:hover {
  box-shadow: 0 1px 25px rgba(255, 1, 1, 0.749);
  width: calc(100% - 0px);
  height: 275px;
  max-width: 225px;
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: 0 1px 25px rgb(65, 36, 36);
}

.imagem-galeria-logo {
  width: calc(100% - 7px);
  height: 250px;
  max-width: 300px;
  border-radius: 8px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 7px rgb(14, 8, 8);
}


.nome-artista {
  width: 100%;
  font-size: 2.5ex;
  color: #333;
  text-align: center;
  margin: 0 auto;
  margin-top: 5px;

}

.nome-trabalho {
  width: 100%;
  display: flex;
  font-size: 2.8ex;
  height: 77px;
  color: #000000;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}


.custom-prev-arrow,
.custom-next-arrow {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0.4;
}

.custom-prev-arrow {
  left: -29px;
}

.custom-next-arrow {
  right: -29px;
}

@media screen and (max-width: 600px) {
  .galeria {
    background-color: #fafafa;
    width: 95%;
    margin: auto;
    margin-bottom: 3rem;
    margin-top: 1.5rem;
  }

  .galeria-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 20px;
  }

  .imagem-galeria {
    width: calc(100% - 10px);
    height: 250px;
    max-width: 300px;
    border-radius: 8px;
    margin: 0 auto;
    /* object-fit: cover; */
    margin-bottom: 15px;
  }


  .custom-prev-arrow {
    left: -20px;
  }

  .custom-next-arrow {
    right: -20px;
  }

  .nome-artista {
    width: 100%;
    font-size: 2.4ex;
    color: #333;
    text-align: center;
    margin: 0 auto;
    margin-top: 5px;
  }

  .nome-trabalho {
    width: 100%;
    font-size: 2.5ex;
    height: 75px;
    color: #000000;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
  }
}