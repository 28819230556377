/* container-ia-main.css */
.container-ia-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.539);
    padding: 3px;
    background-color: transparent;
    border: 2px solid rgba(241, 1, 1, 0.538);
}

.textarea-container {
    width: 100%;
}

.conversation-container {
    min-height: 200px;
    height: 100%;
    background-color: transparent;
}

.container-ia-main span {
    font-size: 30px;
    padding: 50px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    text-align: center;
    color: #333;
    background: none;
    padding: 5px;
    /* margin: 10px 0; */
}

.container-ia-main p {
    font-size: 20px;
    margin-top: 25px;
    padding: 50px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* input-ia.css */
.textarea-ia {
    width: 90%;
    min-height: 70px;
    /* Altura mínima inicial */
    padding: 1rem;
    border: .5px solid rgb(0, 0, 0);
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 1rem;
    font-size: 20px;
    max-width: 1200px;
    box-shadow: 0 5px 15px rgba(26, 26, 26, 0.182);
    transition: min-height 0.2s ease-in-out;
    /* Adiciona transição para suavizar a alteração de altura */
}

.textarea-ia:focus {
    min-height: 150px;

}


.textarea-ia[max-height] {
    overflow-y: auto;
}

/* button.css */
/* .button-ia {
    background-color: #3a900cfa;
    width: 50%;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0 5px 25px rgb(0, 0, 0);
    margin-top: 20px;
    margin-bottom: 25px;
} */

.img-env {
    width: 2.7rem;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.logo-ia {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 25px;
    opacity: .2;
}

.button-ia {
    display: flex;
    width: 50%;
    align-items: end;
    justify-content: end;
    margin: auto;
    background-color: #2e2929;
    color: #ffffff;
    padding: 7px 20px;
    border: .5px solid rgb(255, 0, 0);
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 10px 14px rgba(0, 0, 0, 0.536);
    margin-top: 20px;
    margin-bottom: 25px;

}

.button-ia:hover {
    display: flex;
    align-items: end;
    justify-content: end;
    margin: auto;
    background-color: #050505;
    color: #ffffff;
    padding: 10px 22px;
    border: .5px solid rgb(255, 0, 0);
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.536);
    margin-top: 20px;
    margin-bottom: 25px;

}