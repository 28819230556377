/* ProfilePage.css */

.profile-container-adm {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  max-width: 1200px;
}

.cover-photo-adm {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 4px;
  display: flex;
}

.profile-photo-adm {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  margin-top: -75px;
  border: 5px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.user-info-adm {
  text-align: left;
  margin-top: 20px;
  font-size: 1.3rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 3px;

  border-radius: 4px;
}

.loading-message-adm {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #555;
}

.description-p-adm {
  font-size: 1.2rem;
  width: 97%;
  display: flex;
  text-align: justify;
  line-height: 1.5;
  color: #333;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.description-data-adm {
  display: flex;
}

.responsibility-p-adm {
  margin-left: 0.5rem;
  padding: 1rem;
  font-size: 1.1rem;
}


.profile-input {
  width: 145px;
  position: absolute;
  background-color: #ffffffb5;
  padding: 5px; 
  border: none; 
  border-radius: 2px; 
  cursor: pointer;
  margin-top: 53px ;
  font-size: 11px;
  opacity: 1;
}


.cover-input {
    width: 8.5rem;
    position:absolute;
    background-color: #e6e3e3cc;
    padding: 5px; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer;
    font-size: 11px;
    opacity: 1;
}

.description-input {
    width: 100%;
    height: auto;
    padding: 13px;
    text-align: left;
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  /* Estilo para o botão de edição/salvar */
  .edit-button {
    background-color: #dee0de80;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-button:hover {
    background-color: #ffffff;
    font-size: 18px;
  }
  
  .username-input {
    width: 90%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .username-edit-button {
    background-color: #dee0de80;
    color: #000;
    padding: 4px;
    font-size: 15px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 7px;
  }
  
  .username-edit-button:hover {
    background-color:  #ffffff;
  }

  .email-edit-button {
    background-color: #dee0de80;
    color: #000;
    padding: 4px;
    font-size: 1.1rem;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 7px;
  }

  .email-edit-button:hover {
    background-color:  #ffffff;
  }

  .form-update-post {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

.form-update {
  padding: 15px;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
}

.edit-button-finish {
  width: 95%;
  background-color: #3298b294;
  color: #fffdfd;
  padding: 7px;
  font-size: 1.4rem;
  /* border: 1px solid black; */
  border-radius: 4px;
  cursor: pointer;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-button-finish:hover {
  width: 96%;
  background-color: #3298b2e0;
  color: #000;
  padding: 7px;
  font-size: 1.4rem;
  /* border: 1px solid black; */
  border-radius: 4px;
  cursor: pointer;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-edit-finish {
  background-color: #3298b294; 
  color: white; 
  border: none; 
  padding: 10px 20px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block;
  font-size: 16px; 
  margin: 10px 0; 
  cursor: pointer; 
  border-radius: 5px; 
}

.profile-edit-finish:hover {
  background-color: #ffffff; 
  color: #000;
  border: 1px solid black;
}

.delete-button {
  width: 90%;
  background-color: #ff0000; /* Red color for delete button */
  color: #fff;
  padding: 10px;
  border: none;
  font-size: 1.3rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
}

.custom-ui {
  max-width: 400px; 
  margin: 0 auto; 
  background-color: #fff;
  padding: 20px; 
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.custom-ui-btn {
  width: 55px;
  padding: 5px;
  margin-left: 3px;
  border-radius: 4px;
}

.p-delete-user{
  color: red;
  font-size: 27px;
}

.custom-ui-btn-delete {
  width: 55px;
  padding: 5px;
  margin-left: 3px;
  border-radius: 4px;
  background-color: red;
}

.custom-ui-btn:hover {
  width: 60px;
  padding: 8px;
  border-radius: 4px;
}


.login-label-checkbox-profile-adm {
  width: 10rem;
  display: flex;
  flex-direction:row;
  margin-bottom: 10px;
  font-size: 2rem;
  color: #ffffff;
  background-color: rgba(40, 44, 52, 0);
}

.login-label-checkbox-input-profile-adm {
  width: 80%;
  display: flex;
  margin-bottom: 10px;
  font-size:1.2rem;
  color: #333;
  background-color: rgba(40, 44, 52, 0);
} 


.checkbox-container-profile-adm {
  width:150px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: rgba(170, 176, 189, 0);
}

.checkbox-input-profile-adm {
  width: 1rem;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-custom-profile-adm {
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-left: 3px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.checkbox-checkmark-profile-adm {
  position: absolute;
  top: 2px;
  /* left: 6px; */
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}


.checkbox-input-profile-adm:checked + .checkbox-custom-profile-adm {
  background-color: #000000;
  border: 2px solid #61dafb; 
}

.checkbox-input-profile-adm:checked + .checkbox-custom-profile-adm .checkbox-checkmark-profile-adm {
  opacity: 1; 
}

.p-instagram {
  /* display: flex; */
  word-wrap: break-word;
  word-break: break-all;
}

.cover-photo-adm.selected,
.profile-photo-adm.selected,
.email-edit-button.selected,
.edit-button.selected,
.edit-button-finish.selected {
    border: 1px solid rgb(0, 255, 0);
    background-color: greenyellow;
}
